import * as React from 'react';
import { Link } from 'gatsby';
import './../styles/404.css'

// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: '#8A6534',
  padding: 4,
  backgroundColor: '#FFF4DB',
  fontSize: '1.25rem',
  borderRadius: 4,
};

// markup
const NotFoundPage = () => (
  <div id="notfound">
    <div className="notfound-bg" />
    <div className="notfound">
      <div className="notfound-404">
        <h1>404</h1>
      </div>
      <h2>we are sorry, but the page you requested was not found</h2>

      <a href="#" className="home-btn">
        <Link to="/">Go home</Link>.
      </a>
      <a href="#" className="contact-btn">
        <Link to="https://api.whatsapp.com/send?phone=41766081127">Contact us</Link>.
      </a>
    </div>
  </div>
);


export default NotFoundPage;
